export const endereco = {
  endereco_cep: null,
  endereco_logradouro: null,
  endereco_numero: null,
  endereco_complemento: null,
  endereco_bairro: null,
  endereco_cidade: null,
  endereco_estado: null,
  endereco_residencia_fiscal: null
};

export const perfil_familia = {
  perfil_familia_nome: null,
  perfil_familia_estado_civil: null,
  perfil_familia_regime_comunhao: null,
  perfil_familia_situacao_trabalho: null,
  perfil_familia_possui_dependentes: false,
  perfil_familia_data_nascimento: '',
  perfil_familia_fazer_diagnostico_individual: null,
  perfil_familia_observacao: ''
};

export const pessoa_renda = {
  pessoa_renda_salario_bruto: 0,
  pessoa_renda_salario_liquido: 0,
  pessoa_renda_possui_renda_mensal: false,
  pessoa_renda_possui_renda_anual: false,
  valor_rendimentos_tributaveis: 0,
  pessoa_renda_mensais: [],
  pessoa_renda_anuais: []
};

export const estudo = {
  tempo_restante: null,
  intercambio_tempo: null,
  tempo_seguro_educacional: null,
  esta_estudando: false,
  intercambio: false,
  titular_responsavel_pagamento: false,
  possui_seguro_educacional: false,
  valor_mensalidade: 0,
  intercambio_valor: 0,
  ano_intercambio: null
};

export const pessoa_conjuge = {
  nome_conjuge: null,
  data_nasc_conjuge: '',
  cpf_conjuge: null,
  email_conjuge: null,
  telefone_conjuge: null,
  celular_conjuge: null,
  pref_contato_conjuge: null
};

export const patrimonio_investimento = {
  perfil_investidor: null,
  observacao: '',
  possui_patrimonio_fisico: false,
  emprest_imovel_garantia: false,
  ajuda_perfil_investidor: false,
  patrimonio_itens: [],
  investimento_itens: []
};

export const pessoa_despesa = {
  tipo_declaracao_ir: null,
  quantos_dependentes: 0,
  quantos_alimentando: 0,
  despesa_fixa_mensal: 0,
  contribuicao_inss: 0,
  dependentes: 0,
  despesas_educacao: 0,
  despesas_medicas: 0,
  pensao_alimenticia: 0,
  pgbl_fapi_funpresp: 0,
  livro_caixa: 0,
  outras_despesas: 0,
  ir_retido_deducoes: 0,
  expectativa_renda_aposentadoria: 0,
  divida_curto_prazo: 0,
  divida_medio_prazo: 0,
  divida_longo_prazo: 0,
  contribuicao_cliente: 0,
  contribuicao_conjuge: 0,
  outro_contribuinte: 0,
  media_poupanca_total: 0,
  observacao: '',
  operadora: null,
  contribuinte_previdencia_aposentado: false,
  aposentado_maior_65_anos: false,
  possui_divida_curto_prazo: false,
  possui_divida_medio_prazo: false,
  possui_divida_longo_prazo: false,
  interesse_estudo_poupar_mais: false,
  despesa_itens: []
};

export const pessoa_plano = {
  plano_quanto_tempo_protecao: null,
  plano_quantos_anos_liberdade_financeira: null,
  plano_ate_quantos_anos_liberdade_financeira: null,
  planob_motocicleta_cilindrada: null,
  plano_renda_mensal_necessaria: 0,
  plano_renda_desejada_liberdade_financeira: 0,
  planob_rotina_exposicao_risco: null,
  planob_seguro_vida: null,
  planob_moticicleta_finalidade: null,
  plano_observacao: '',
  planob_observacao: '',
  planob_dependente_alem_filhos_grau_parentesco: null,
  planob_dependente_alem_filhos_grau_parentesco_customizado: null,
  plano_comprar_imovel_exterior: false,
  planob_reservas_suficientes: false,
  planob_depende_trabalho: false,
  planob_possui_rotina_exposicao_risco: false,
  planob_possui_dependente_alem_filhos: false,
  planob_possui_seguro_vida: false,
  planob_possui_motocicleta: false,
  planob_pessoa_politicamente_exposta: false
};

export const pessoa_previdencia_privada = {
  valor_investimento_mensal: 0,
  pessoa_fisica: false,
  pessoa_juridica: false,
  pessoa_fisica_instituicao: null,
  pessoa_juridica_instituicao: null,
  observacao: '',
  possui_investimento: false,
  pensou_iniciar: false,
  previdencia_fundos: []
};

export const pessoa_saude = {
  ano_parou_fumar: null,
  altura: null,
  peso: null,
  valor_reembolso: 0,
  remedio_controlado: null,
  dosagem_remedio_controlado: null,
  exame_anormal: null,
  historico_doenca_preexistente: null,
  previsao_cirurgia: null,
  historico_doenca_familia: null,
  contato_razao_social: null,
  contato_cnpj: null,
  contato_representante: null,
  contato_telefone: null,
  contato_email: null,
  observacao: '',
  observacao_dependentes: '',
  fumante: null,
  toma_remedio_controlado: false,
  possui_exame_anormal: false,
  possui_historico_doenca_preexistente: false,
  possui_previsao_cirurgia: false,
  possui_historico_doenca_familia: false,
  satisfeito: false,
  avaliou_reducao_custos: false
};

export const saude = {
  valor_mensal: 0,
  valor_reembolso: 0,
  tipo_pessoa: null,
  operadora: null,
  nome_produto: null,
  tipo_cobertura: null,
  possui_preferencia: null,
  estado: null,
  cidade: null,
  acomodacao: null,
  possui_seguro_plano: false,
  coparticipacao: false,
  incluso_valor_titular: false,
  incluso_valor_conjuge: false
};

export const pessoa_renda_mensal = {
  nome: null,
  valor: 0
};

export const pessoa_renda_anual = {
  nome: null,
  valor: 0,
  periodicidade: 1
};

export const patrimonio_item = {
  valor: 0,
  valor_mensal: 0,
  cobertura_incendio: 0,
  cobertura_roubo: 0,
  nome: null,
  tipo: null,
  quitado: false,
  pode_ser_vendido: false,
  despesa_parcelas_restantes: null,
  despesa_valor_entrada: 0,
  despesa_saldo_pagar_financeamento: 0,
  despesa_forma_construcao: 0,
  despesa_parcela_inicial: 0,
  despesa_indice_correcao: null,
  despesa_taxa_juros_financeamento: 0,
  despesa_operadora: null
};

export const investimento_item = {
  valor: 0,
  rentabilidade: 0,
  nome: null,
  tipo: null,
  cnpj_fundo: null,
  liquidez: null,
  faz_aportes_regularmente: false,
  frequencia_aportes: null,
  frequencia_aportes_customizada: null,
  valor_aportes: 0
};

export const despesa_item = {
  forma_construcao: 0,
  parcelas_restantes: 0,
  valor_parcela: 0,
  taxa_juros_financeamento: 0,
  saldo_pagar_financeamento: 0,
  nome: null,
  indice_correcao: null,
  quitado: false
};

export const grupo_seguro_item = {
  valor_mensal: 0,
  valor_cobertura: 0,
  seguradora: null,
  tipo_seguro: null,
  tipo_pessoa: null,
  apolice_nome: null,
  file: null
};

export const seguro_item = {
  valor_mensal: 0,
  valor_cobertura: 0,
  seguradora: null,
  seguradora_outra: null,
  tipo_seguro: null,
  resgatavel: false,
  apolice_nome: null,
  quanto_tempo: null,
  file: null
};

export const previdencia_fundo = {
  valor_atual: 0,
  aporte_mensal: 0,
  nome: null,
  cnpj: null,
  tipo_plano: null,
  tributacao: null,
  tipo_renda: null,
  processo_susep: null,
  custo_inventario: false,
  faz_aportes_regularmente: false,
  valor_aportes: 0,
  frequencia_aportes: null,
  frequencia_aportes_customizada: null
};

export const prioridade = {
  tipo: null,
  nome_personalizado: null,
  nota: null
};

export const indicacao = {
  nome: null,
  telefone: null,
  email: null,
  numero_dependentes: null
};

export const sonho = {
  ano: null,
  valor: 0,
  valor_construido: 0,
  investimento_mensal: 0,
  nome: null,
  tipo: null,
  tipo_customizado: null
};

export const dependente = {
  nome_dependente: null,
  sexo_dependente: 'M',
  cpf_dependente: null,
  rg_dependente: null,
  data_nasc_dependente: '',
  grau_parentesco_dependente: null,
  grau_parentesco_customizado: null,
  estudo: estudo,
  saude: saude
};

export const beneficiario = {
  nome_beneficiario: null,
  cpf_beneficiario: null,
  rg_beneficiario: null,
  percentual_beneficiario: 0,
  data_nascimento_beneficiario: null,
  grau_parentesco_beneficiario: null,
  grau_parentesco_customizado_beneficiario: null
};

export const pessoa_remedio = {
  nome: '',
  dosagem: ''
};

export const pessoa_pagamento = {
  periodicidade_pagamento: null,
  receita_bruta_anual: 0,
  responsavel_pagamento_diferente: false,
  forma_pagamento: null,
  nome: null,
  rg: null,
  cpf: null,
  cnpj: null,
  telefone: null,
  email: null,
  profissao: null,
  instituicao_trabalho: null,
  endereco
};

export const dependente_plano = {
  quanto_tempo_protecao: null,
  grau_parentesco: null,
  grau_parentesco_customizado: null,
  renda_mensal_necessaria: 0
};

export default {
  endereco,
  perfil_familia,
  pessoa_renda,
  estudo,
  pessoa_conjuge,
  patrimonio_investimento,
  pessoa_despesa,
  pessoa_plano,
  pessoa_previdencia_privada,
  pessoa_saude,
  saude,
  pessoa_renda_mensal,
  pessoa_renda_anual,
  patrimonio_item,
  investimento_item,
  despesa_item,
  grupo_seguro_item,
  seguro_item,
  previdencia_fundo,
  prioridade,
  indicacao,
  sonho,
  dependente,
  pessoa_remedio,
  pessoa_pagamento,
  dependente_plano
};
