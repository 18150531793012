<template>
  <main>
    <PageHeader :items="breadcrumb" />

    <ModalAddComment
      idColumn="diagnostico_documento_id"
      urlResource="diagnostico-documento"
      urlAppend="/comentario-especialista"
      commentColumn="comentario"
      @onSuccess="onSuccessAddComment()"
    />

    <div class="d-flex justify-content-center mb-3">
      <b-spinner
        v-if="loading"
        variant="primary"
        type="grow"
        label="Spinning"
      ></b-spinner>
    </div>

    <b-row v-if="!loading">
      <b-col>
        <b-card>
          <b-row>
            <b-col sm="7" lg="8" xl="10">
              <h5>
                Efetivação da proposta -
                <span class="text-primary" v-if="diagnosis">
                  Proposta de {{ diagnosis.pessoa.pessoa_nome }}
                </span>
              </h5>

              <div style="width: 100%">
                <p style="color: #74788d; font-size: 13; font-family: poppins">
                  Confira abaixo a lista de documentos e informações que o
                  Corretor enviou para completar a venda e efetivar os produtos.
                </p>
              </div>
            </b-col>

            <b-col sm="5" lg="4" xl="2">
              <div>Corretor responsável</div>
              <div
                class="font-weight-bold"
                v-if="diagnosis && diagnosis.usuario.pessoa"
              >
                {{ diagnosis.usuario.pessoa.pessoa_nome }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-4" style="background-color: #f7f7fa">
            <b-col cols="12">
              <b-row class="bg-secondary text-white" style="padding: 0.5rem">
                <b-col cols="12" class="d-flex align-items-center">
                  <i
                    class="fas fa-file-alt"
                    size="40"
                    style="margin-right: 1rem; font-size: 1rem; color: #fff"
                  ></i>
                  <span>DADOS COMPLEMENTARES - para ativação dos produtos</span>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              style="margin-top: 2rem; border-right: 0.5px solid #e7ebf0"
              v-if="customer"
            >
              <b-row>
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>INFORMAÇÕES DO CLIENTE</span>
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nome_completo'
                    )
                  "
                >
                  <div class="font-weight-bold">Nome completo</div>
                  <div>{{ customer.pessoa.pessoa_nome }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'data_nascimento'
                    )
                  "
                >
                  <div class="font-weight-bold">Data de nascimento</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_data_nascimento
                        | formatDate('DD/MM/YYYY')
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('sexo')
                  "
                >
                  <div class="font-weight-bold">Sexo</div>
                  <div>{{ customer.pessoa.pessoa_sexo | genderName }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'profissao'
                    )
                  "
                >
                  <div class="font-weight-bold">Profissão</div>
                  <div>{{ customer.pessoa.pessoa_profissao }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="diagnosis.diagnostico_campos_fechamento.includes('cpf')"
                >
                  <div class="font-weight-bold">CPF</div>
                  <div>{{ customer.pessoa.pessoa_cpf | cpf }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="diagnosis.diagnostico_campos_fechamento.includes('rg')"
                >
                  <div class="font-weight-bold">
                    Tipo do documento de identificação
                  </div>
                  <div>{{ customer.pessoa.tipo_documento_identificacao }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="diagnosis.diagnostico_campos_fechamento.includes('rg')"
                >
                  <div class="font-weight-bold">Documento de identificação</div>
                  <div>{{ customer.pessoa.documento_identificacao }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'data_expedicao'
                    )
                  "
                >
                  <div class="font-weight-bold">Data de expedição</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_data_expedicao
                        | formatDate('DD/MM/YYYY')
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'orgao_expedidor'
                    )
                  "
                >
                  <div class="font-weight-bold">Orgão expedidor</div>
                  <div>{{ customer.pessoa.pessoa_orgao_expedidor }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('cnpj')
                  "
                >
                  <div class="font-weight-bold">CNPJ</div>
                  <div>{{ customer.pessoa.pessoa_cnpj }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('email')
                  "
                >
                  <div class="font-weight-bold">E-mail</div>
                  <div>{{ customer.pessoa.pessoa_email }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('telefone')
                  "
                >
                  <div class="font-weight-bold">Telefone</div>
                  <div>{{ customer.pessoa.pessoa_telefone }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'estado_civil'
                    )
                  "
                >
                  <div class="font-weight-bold">Estado civil</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_estado_civil | maritalStatusName
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nacionalidade'
                    )
                  "
                >
                  <div class="font-weight-bold">Nacionalidade</div>
                  <div>
                    {{ customer.pessoa.pessoa_nacionalidade }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'nome_instituicao_trabalha'
                    )
                  "
                >
                  <div class="font-weight-bold">
                    Nome da instituição que trabalha
                  </div>
                  <div>
                    {{ customer.pessoa.pessoa_instituicao_trabalho }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes(
                      'receita_bruta_anual'
                    )
                  "
                >
                  <div class="font-weight-bold">Renda bruta anual</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_renda.pessoa_renda_salario_bruto *
                      12
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('altura')
                  "
                >
                  <div class="font-weight-bold">Altura</div>
                  <div>
                    {{ customer.pessoa.pessoa_saude.altura | decimal }} m
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('peso')
                  "
                >
                  <div class="font-weight-bold">Peso</div>
                  <div>
                    {{ customer.pessoa.pessoa_saude.peso | decimal }} kg
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    diagnosis.diagnostico_campos_fechamento.includes('fumante')
                  "
                >
                  <div class="font-weight-bold">Fumante</div>
                  <div>
                    {{ customer.pessoa.pessoa_saude.fumante | statusSmoke }}
                  </div>
                </b-col>
              </b-row>

              <b-row
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'endereco_completo'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>Endereço</span>
                  </div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">CEP</div>
                  <div>{{ customer.pessoa.endereco.endereco_cep }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Bairro</div>
                  <div>{{ customer.pessoa.endereco.endereco_bairro }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Rua</div>
                  <div>{{ customer.pessoa.endereco.endereco_logradouro }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Número</div>
                  <div>{{ customer.pessoa.endereco.endereco_numero }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Estado</div>
                  <div>{{ customer.pessoa.endereco.endereco_estado }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Cidade</div>
                  <div>{{ customer.pessoa.endereco.endereco_cidade }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Complemento</div>
                  <div>{{ customer.pessoa.endereco.endereco_complemento }}</div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">Residência fiscal</div>
                  <div>
                    {{ customer.pessoa.endereco.endereco_residencia_fiscal }}
                  </div>
                </b-col>
              </b-row>

              <b-row
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'preenchimento_dps'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>Preenchimento DPS</span>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <div class="font-weight-bold">
                        Possui alguma rotina que aumente a sua exposição ao
                        risco
                      </div>
                      <div>
                        {{
                          customer.pessoa.pessoa_plano
                            .planob_possui_rotina_exposicao_risco
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_plano
                          .planob_possui_rotina_exposicao_risco
                      "
                    >
                      <div class="font-weight-bold">Qual?</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_plano
                            .planob_rotina_exposicao_risco
                        }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" xl="4" class="mb-2">
                      <div class="font-weight-bold">Possui motocicleta</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_plano.planob_possui_motocicleta
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_plano.planob_possui_motocicleta
                      "
                    >
                      <div class="font-weight-bold">Cilindrada</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_plano
                            .planob_motocicleta_cilindrada
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_plano.planob_possui_motocicleta
                      "
                    >
                      <div class="font-weight-bold">Finalidade</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_plano
                            .planob_moticicleta_finalidade
                        }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <div class="font-weight-bold">
                    Toma algum remédio controlado
                  </div>
                  <div>
                    {{
                      customer.pessoa.pessoa_saude.toma_remedio_controlado
                        ? 'Sim'
                        : 'Não'
                    }}
                  </div>

                  <div
                    v-if="customer.pessoa.pessoa_saude.toma_remedio_controlado"
                  >
                    <div
                      v-for="(remedio, index) in customer.pessoa
                        .pessoa_remedios"
                      :key="index"
                    >
                      <b-col md="6" xl="4" class="mb-2">
                        <div class="font-weight-bold">Nome</div>
                        <div>
                          {{ remedio.nome }}
                        </div>
                      </b-col>

                      <b-col md="6" xl="4" class="mb-2">
                        <div class="font-weight-bold">Dosagem</div>
                        <div>
                          {{ remedio.dosagem }}
                        </div>
                      </b-col>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <div class="font-weight-bold">
                        Previsão de cirurgia futura
                      </div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude.possui_previsao_cirurgia
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_saude.possui_previsao_cirurgia
                      "
                    >
                      <div class="font-weight-bold">Quais cirúrgias?</div>
                      <div>
                        {{ customer.pessoa.pessoa_saude.previsao_cirurgia }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <div class="font-weight-bold">
                        Histórico de doença na familia (pais/irmãos)
                      </div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude
                            .possui_historico_doenca_familia
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_saude
                          .possui_historico_doenca_familia
                      "
                    >
                      <div class="font-weight-bold">Quais doenças?</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude.historico_doenca_familia
                        }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <div class="font-weight-bold">
                        Histórico de doenças pré-existentes
                      </div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude
                            .possui_historico_doenca_preexistente
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="
                        customer.pessoa.pessoa_saude
                          .possui_historico_doenca_preexistente
                      "
                    >
                      <div class="font-weight-bold">Qual?</div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude
                            .historico_doenca_preexistente
                        }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <div class="font-weight-bold">
                        Exame fora da normalidade
                      </div>
                      <div>
                        {{
                          customer.pessoa.pessoa_saude.possui_exame_anormal
                            ? 'Sim'
                            : 'Não'
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="customer.pessoa.pessoa_saude.possui_exame_anormal"
                    >
                      <div class="font-weight-bold">Qual?</div>
                      <div>
                        {{ customer.pessoa.pessoa_saude.exame_anormal }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_pagamento'
                  )
                "
              >
                <b-col cols="12">
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>Pagamento</span>
                  </div>
                </b-col>

                <b-col md="6" class="mb-2">
                  <div class="font-weight-bold">Forma de pagamento</div>
                  <div>
                    {{ customer.pessoa.pessoa_pagamento.forma_pagamento }}
                  </div>
                </b-col>

                <b-col md="6" class="mb-2">
                  <div class="font-weight-bold">Periodicidade</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.periodicidade_pagamento
                        | periodicidadePagamento
                    }}
                  </div>
                </b-col>

                <b-col md="6" xl="4" class="mb-2">
                  <div class="font-weight-bold">
                    Responsável pelo pagamento diferente do segurado
                  </div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento
                        .responsavel_pagamento_diferente
                        ? 'Sim'
                        : 'Não'
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Nome completo</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.nome }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Profissão</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.profissao }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">CPF</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.cpf | cpf }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">RG</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.rg }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">CNPJ</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.cnpj }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">E-mail</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.email }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Telefone</div>
                  <div>{{ customer.pessoa.pessoa_pagamento.telefone }}</div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">
                    Nome da instituição que trabalha
                  </div>
                  <div>
                    {{ customer.pessoa.pessoa_pagamento.instituicao_trabalho }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Renda bruta anual</div>
                  <div>
                    {{ customer.pessoa.pessoa_pagamento.receita_bruta_anual }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">CEP</div>
                  <div>
                    {{ customer.pessoa.pessoa_pagamento.endereco.endereco_cep }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Bairro</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco.endereco_bairro
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Rua</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco
                        .endereco_logradouro
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Número</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco.endereco_numero
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Estado</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco.endereco_estado
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Cidade</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco.endereco_cidade
                    }}
                  </div>
                </b-col>

                <b-col
                  md="6"
                  xl="4"
                  class="mb-2"
                  v-if="
                    customer.pessoa.pessoa_pagamento
                      .responsavel_pagamento_diferente
                  "
                >
                  <div class="font-weight-bold">Complemento</div>
                  <div>
                    {{
                      customer.pessoa.pessoa_pagamento.endereco
                        .endereco_complemento
                    }}
                  </div>
                </b-col>
              </b-row>

              <div
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_dependentes'
                  )
                "
              >
                <div>
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>DEPENDENTES</span>
                  </div>
                </div>

                <p class="font-weight-bold">
                  Este cliente tem
                  {{ customer.pessoa.dependentes.length }} dependentes
                </p>

                <div
                  v-for="(dependent, index) in customer.pessoa.dependentes"
                  :key="index"
                >
                  <p class="font-weight-bold">{{ index + 1 }}. DEPENDENTE</p>
                  <b-row>
                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_dependentes'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Nome completo do dependente
                      </div>
                      <div>{{ dependent.nome_dependente }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_dependentes'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Data de nascimento do dependente
                      </div>
                      <div>
                        {{
                          dependent.data_nasc_dependente
                            | formatDate('DD/MM/YYYY')
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'parentesco_dependentes'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Grau de parentesco do dependente
                      </div>
                      <div>{{ dependent.grau_parentesco_dependente }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'cpf_dependentes'
                        )
                      "
                    >
                      <div class="font-weight-bold">CPF</div>
                      <div>{{ dependent.cpf_dependente }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'rg_dependentes'
                        )
                      "
                    >
                      <div class="font-weight-bold">RG</div>
                      <div>{{ dependent.rg_dependente }}</div>
                    </b-col>
                  </b-row>

                  <hr v-if="index !== customer.pessoa.dependentes.length - 1" />
                </div>
              </div>

              <div
                v-if="
                  diagnosis.diagnostico_campos_fechamento.includes(
                    'informacoes_beneficiarios'
                  )
                "
              >
                <div>
                  <div style="margin-top: 1rem" class="hrdivider">
                    <hr />
                    <span>BENEFICIÁRIOS</span>
                  </div>
                </div>

                <p class="font-weight-bold">
                  Este cliente tem
                  {{ customer.pessoa.beneficiarios.length }} beneficiarios
                </p>

                <div
                  v-for="(dependent, index) in customer.pessoa.beneficiarios"
                  :key="index"
                >
                  <p class="font-weight-bold">{{ index + 1 }}. DEPENDENTE</p>
                  <b-row>
                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'nome_completo_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Nome completo do beneficiário
                      </div>
                      <div>{{ dependent.cpf_beneficiario }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'numero_cpf_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">CPF</div>
                      <div>{{ dependent.rg_beneficiario }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'numero_rg_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">RG</div>
                      <div>{{ dependent.rg_dependente }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'data_nascimento_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Data de nascimento do beneficiário
                      </div>
                      <div>
                        {{
                          dependent.data_nascimento_beneficiario
                            | formatDate('DD/MM/YYYY')
                        }}
                      </div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'parentesco_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Grau de parentesco do beneficiário
                      </div>
                      <div>{{ dependent.grau_parentesco_dependente }}</div>
                    </b-col>

                    <b-col
                      md="6"
                      xl="4"
                      class="mb-2"
                      v-if="
                        diagnosis.diagnostico_campos_fechamento.includes(
                          'percentual_beneficiarios'
                        )
                      "
                    >
                      <div class="font-weight-bold">
                        Percentual do beneficiário
                      </div>
                      <div>
                        {{ dependent.percentual_beneficiario | decimal }}%
                      </div>
                    </b-col>
                  </b-row>

                  <hr
                    v-if="index !== customer.pessoa.beneficiarios.length - 1"
                  />
                </div>
              </div>

              <div class="d-flex justify-content-between mt-5">
                <b-button
                  variant="success"
                  class="px-5 mr-3"
                  style="font-size: 15px"
                  @click="approveData"
                >
                  APROVAR DADOS
                </b-button>
                <b-button
                  variant="danger"
                  class="px-5 mr-3"
                  style="font-size: 15px"
                  @click="refuseData"
                >
                  RECUSAR DADOS
                </b-button>
              </div>
            </b-col>

            <b-col cols="12" class="mt-5">
              <b-row class="bg-secondary text-white" style="padding: 0.5rem">
                <b-col cols="12" class="d-flex align-items-center">
                  <i
                    class="fas fa-paper-plane"
                    style="margin-right: 1rem; font-size: 1rem; color: #fff"
                  ></i>
                  <span>DOCUMENTOS ENVIADOS</span>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" style="padding: 1rem">
              <div style="margin-top: 1rem">
                <div style="margin-top: 1rem" class="hrdivider">
                  <hr />
                  <span>DOCUMENTOS COM APROVAÇÕES PENDENTES</span>
                </div>
                <span>Confira os documentos enviados pelo corretor:</span>

                <div
                  class="mt-3 d-flex align-items-center justify-content-between"
                >
                  <b-button
                    class="mr-3 w-100"
                    :variant="
                      documentTypeSelected === 0 ? 'dark' : 'outline-dark'
                    "
                    @click="selectDocumentsWaiting()"
                  >
                    Verificar
                  </b-button>
                  <b-button
                    class="mr-3 w-100"
                    :variant="
                      documentTypeSelected === 2 ? 'dark' : 'outline-dark'
                    "
                    @click="selectRejectedDocuments()"
                  >
                    Recusados
                  </b-button>
                  <b-button
                    class="w-100"
                    :variant="
                      documentTypeSelected === 1 ? 'dark' : 'outline-dark'
                    "
                    @click="selectVerifiedDocuments()"
                  >
                    Verificados
                  </b-button>
                </div>

                <div v-if="documentsSelected.length === 0" class="mt-3">
                  Nenhum documento encontrado
                </div>

                <div
                  v-for="document in documentsSelected"
                  :key="document.diagnostico_documento_id"
                  style="margin: 1rem 0"
                >
                  <p style="margin-bottom: 4px">
                    {{ document.titulo }}

                    <span v-if="document.download_url">
                      - Enviado dia {{ document.data_alteracao | formatDate }}
                    </span>
                  </p>

                  <div v-if="!document.download_url">Não enviado</div>

                  <b-button-group
                    class="w-100 mb-3"
                    v-if="document.download_url"
                    @click="() => viewDocument(document)"
                  >
                    <b-button
                      class="border-right-0"
                      style="
                        width: 43px;
                        background: #dddfe3;
                        border-color: #ced4da;
                      "
                    >
                      <i
                        class="fa fa-eye"
                        style="font-size: 0.95rem; color: #495057"
                      />
                    </b-button>

                    <b-button
                      class="border-right-0 border-left-0 px-3"
                      style="
                        background: #eff2f7;
                        color: #495057 !important;
                        border-color: #ced4da;
                      "
                    >
                      Visualizar
                    </b-button>

                    <b-button
                      class="border-left-0 w-100 text-left"
                      style="
                        background: #f7f7fa;
                        color: #495057 !important;
                        border-color: #ced4da;
                      "
                    >
                      {{ document.nome_original }}
                    </b-button>
                  </b-button-group>

                  <b-form-file
                    v-model="document.file"
                    :file-name-formatter="formatNames"
                    @input="() => handleFileUpload(document)"
                  />

                  <div
                    class="d-flex align-items-center justify-content-between mt-3"
                  >
                    <b-form-radio-group
                      v-model="document.status_aprovacao"
                      :options="approveOptions"
                      class="mr-3"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                    />

                    <ButtonEditComment
                      :title="`${
                        document.diagnostico_comentario_especialista &&
                        document.diagnostico_comentario_especialista.comentario
                          ? 'EDITAR'
                          : 'ADICIONAR'
                      } COMENTÁRIO`"
                      style_icon="font-size: 1.2rem;"
                      style_text="font-size: 12px; width: 110px; text-align: left;"
                      icon="plus"
                      :openModalEditComment="
                        () => openModalAddEditCommentSpecialist(document)
                      "
                    />
                  </div>
                </div>

                <div>
                  <b-button
                    block
                    variant="primary"
                    class="px-5 mr-3 mt-5"
                    style="font-size: 15px"
                    @click="submitAnswers"
                  >
                    ENVIAR RESPOSTAS
                  </b-button>
                </div>
              </div>

              <div
                style="margin-top: 4rem"
                v-show="verifiedDocuments.length > 0"
              >
                <div class="hrdivider">
                  <hr style="background: #078d42" />
                  <span style="color: #078d42">VERIFICADOS</span>
                </div>

                <div
                  v-for="document in verifiedDocuments"
                  :key="document.diagnostico_documento_id"
                  style="margin: 1rem 0"
                >
                  <p style="margin-bottom: 0px; color: #078d42">
                    <strong>{{ document.titulo }}</strong> - Enviado dia
                    {{ document.data_alteracao | formatDate }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <ModalAddEditCommentSpecialist
      :isOpen="isOpenModalAddEditCommentSpecialist"
      :data="modalDataSpecialist"
      @onSaveComment="
        (diagnosisComment) => onSaveCommentSpecialist(diagnosisComment)
      "
      @closeModal="closeModalAddEditCommentSpecialist()"
    />
  </main>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import ButtonEditComment from '@/components/Buttons/ButtonEditComment';
import ModalAddComment from '@/components/common/Modals/ModalAddComment';
import ModalAddEditCommentSpecialist from '@/components/common/Modals/ModalAddEditCommentSpecialist';

import {
  requestDiagnosisSpecialist,
  requestDocumentDiagnosis,
  updatePhaseSpecialist,
  updatePhase,
  updateDocumentDiagnosis,
  uploadDocumentFile
} from '@/services/requests/diagnosis';
import { requestGetCustomerId } from '@/services/requests/customer';

import { approveOptions } from '@/constants/options';
import { cloneObject } from '@/helpers';
import defaultData from '@/constants/defaultData';

export default {
  name: 'ProposalExecution',
  components: {
    PageHeader,
    ButtonEditComment,
    ModalAddComment,
    ModalAddEditCommentSpecialist
  },
  data() {
    return {
      loading: false,
      diagnosis: null,
      customer: null,
      errors: {},
      documentsWaiting: [],
      verifiedDocuments: [],
      rejectedDocuments: [],
      documentTypeSelected: 0,
      approveOptions,
      isOpenModalAddEditCommentSpecialist: false,
      modalDataSpecialist: null
    };
  },
  methods: {
    loadDiagnosis() {
      this.loading = true;
      requestDiagnosisSpecialist(this.diagnosisId)
        .then((res) => {
          this.loading = false;
          const diagnosis = res.data.result.data;
          this.diagnosis = diagnosis;

          this.updateDocumentsGroups();

          this.loadCustomer();
        })
        .catch(() => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    updateDocumentsGroups() {
      this.documentsWaiting = this.diagnosis.diagnostico_documentos_fechamento.filter(
        (document) => document.status_aprovacao === 0
      );
      this.verifiedDocuments = this.diagnosis.diagnostico_documentos_fechamento.filter(
        (document) => document.status_aprovacao === 1
      );
      this.rejectedDocuments = this.diagnosis.diagnostico_documentos_fechamento.filter(
        (document) => document.status_aprovacao === 2
      );
    },

    loadCustomer() {
      this.loading = true;
      requestGetCustomerId(this.diagnosis.pessoa.cliente.cliente_id)
        .then((res) => {
          this.loading = false;
          const customer = res.data.result.data;

          if (!customer.pessoa.pessoa_pagamento) {
            customer.pessoa.pessoa_pagamento = cloneObject(
              defaultData.pessoa_pagamento
            );
          }

          if (!customer.pessoa.pessoa_pagamento.endereco) {
            customer.pessoa.pessoa_pagamento.endereco = cloneObject(
              defaultData.endereco
            );
          }

          this.customer = customer;
        })
        .catch((error) => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },

    viewDocument(document) {
      this.loading = true;
      requestDocumentDiagnosis(document.diagnostico_documento_id)
        .then((res) => {
          this.loading = false;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', document.nome_original);

          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    openModalAddComment(document) {
      this.$store.state.modal.itemModalAddComment = {
        diagnostico_documento_id: document.diagnostico_documento_id,
        comentario: document.diagnostico_comentario_especialista
          ? document.diagnostico_comentario_especialista.comentario
          : ''
      };
      this.$store.state.modal.isOpenModalAddComment = true;
    },

    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} arquivos selecionados`;
    },

    approveData() {
      if (!this.diagnosis) return;

      const diagnosisId = this.diagnosis.diagnostico_id;

      const data = {
        estagio_id: 4
      };

      // if (
      //   this.diagnosis.diagnostico_historico_especialista.estagio_id >=
      //   data.estagio_id
      // ) {
      //   this.$router.push({
      //     name: 'InputProposals',
      //     params: { diagnosisId }
      //   });
      //   return;
      // }

      updatePhaseSpecialist(diagnosisId, data)
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({
            name: 'InputProposals',
            params: { diagnosisId }
          });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    refuseData() {
      // sem utilizade para este botão ainda
      return;

      /* eslint-disable */

      if (!this.diagnosis) return;

      const diagnosisId = this.diagnosis.diagnostico_id;

      updatePhase()
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({
            name: 'SupplierApproval',
            params: { diagnosisId }
          });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });

      /* eslint-enable */
    },

    updateDocumentRequest(document) {
      const data = {
        status_aprovacao: document.status_aprovacao
      };

      return updateDocumentDiagnosis(document.diagnostico_documento_id, data);
    },

    submitAnswers() {
      const documents = this.diagnosis.diagnostico_documentos_fechamento;

      if (documents.length === 0) return;

      const promises = [];

      documents.forEach((document) => {
        if (!document.status_aprovacao) return;

        const promise = this.updateDocumentRequest(document);
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          this.loadDiagnosis();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    selectDocumentsWaiting() {
      this.documentTypeSelected = 0;
    },

    selectRejectedDocuments() {
      this.documentTypeSelected = 2;
    },

    selectVerifiedDocuments() {
      this.documentTypeSelected = 1;
    },

    onSuccessAddComment() {
      this.loadDiagnosis();
    },

    openModalAddEditCommentSpecialist(document) {
      this.modalDataSpecialist = document.diagnostico_comentario_especialista || {
        diagnostico_comentario_id: null,
        diagnostico_documento_id: document.diagnostico_documento_id,
        comentario: null,
        download_url: null
      };

      this.isOpenModalAddEditCommentSpecialist = true;
    },

    onSaveCommentSpecialist(diagnosisComment) {
      const documentIndex = this.documentsSelected.findIndex(
        (document) =>
          document.diagnostico_documento_id ===
          diagnosisComment.diagnostico_documento_id
      );

      const documentTypeKey =
        this.documentTypeSelected === 1
          ? 'verifiedDocuments'
          : this.documentTypeSelected === 2
          ? 'rejectedDocuments'
          : 'documentsWaiting';

      this.$set(
        this[documentTypeKey][documentIndex],
        'diagnostico_comentario_especialista',
        diagnosisComment
      );
      this.closeModalAddEditCommentSpecialist();
    },

    closeModalAddEditCommentSpecialist() {
      this.isOpenModalAddEditCommentSpecialist = false;
    },

    handleFileUpload(document) {
      if (!document.file) return;

      uploadDocumentFile(document.diagnostico_documento_id, document.file)
        .then((res) => {
          const updatedDocument = res.data.result.data;

          const documentIndex = this.diagnosis.diagnostico_documentos_fechamento.findIndex(
            (doc) =>
              doc.diagnostico_documento_id === document.diagnostico_documento_id
          );

          this.$set(
            this.diagnosis.diagnostico_documentos_fechamento,
            documentIndex,
            updatedDocument
          );
          this.updateDocumentsGroups();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    diagnosisId() {
      return this.$route.params.diagnosisId;
    },
    documentsSelected() {
      if (!this.diagnosis) return [];

      if (this.documentTypeSelected === 0) return this.documentsWaiting;
      if (this.documentTypeSelected === 1) return this.verifiedDocuments;
      if (this.documentTypeSelected === 2) return this.rejectedDocuments;

      return [];
    },

    breadcrumb() {
      return [
        {
          text: 'Inicial',
          href: '/'
        },
        {
          text: 'Painel de atividades',
          href: '/diagnosis/complete'
        },
        {
          text: 'Preparar cotação',
          href: `/activity-panel/proposal-quotation/${this.diagnosisId}`
        },
        {
          text: 'Verificar resposta da proposta',
          href: `/activity-panel/check-proposal-response/${this.diagnosisId}`
        },
        {
          text: 'Efetivação da proposta',
          active: true
        }
      ];
    }
  },
  mounted() {
    this.loadDiagnosis();
  }
};
</script>

<style lang="scss">
.hrdivider {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  text-align: left;

  span {
    position: absolute;
    top: -11px;
    background: rgb(247, 247, 250);
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
