<template>
  <b-modal v-model="isOpenModal" @ok="handleOk">
    <div v-if="data">
      <form @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="comment-textarea">
          <b-form-textarea
            id="comment-textarea"
            v-model="comment"
            rows="5"
            placeholder="Texto com o comentário..."
          />
        </b-form-group>
      </form>

      <b-form-file
        browse-text="Selecionar"
        placeholder="Nome do arquivo..."
        drop-placeholder="Solte aqui..."
        v-model="file"
      />

      <div
        v-if="data.download_url"
        class="mt-3 d-flex justify-content-between align-items-center"
      >
        <div v-if="!deleteFile">
          <p class="mb-0">{{ data.nome_original }}</p>
          <a :href="data.download_url" target="_blank"> Visualizar </a>
        </div>
        <div v-else>
          <span class="text-secondary">O arquivo será excluído</span>
          <p
            class="mb-0 text-primary cursor-pointer"
            @click="handleUndoDeleteFile()"
          >
            Desfazer
          </p>
        </div>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeleteFile()"
          :disabled="deleteFile"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </div>
    </div>

    <template #modal-header>
      <div style="margin: 0 auto">
        <h5 style="color: var(--grey)">
          {{ data.diagnostico_comentario_id ? 'Editar' : 'Adicione o seu' }}
          comentário
        </h5>
      </div>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="md" variant="danger" @click="cancel()">
        Cancelar
      </b-button>

      <b-button size="md" variant="success" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  addCommentSpecialist,
  updateCommentSpecialist,
  uploadCommentFileSpecialist
} from '@/services/requests/diagnosis';

export default {
  name: 'ModalAddEditCommentSpecialist',
  props: {
    isOpen: Boolean,
    data: Object
  },
  data() {
    return {
      comment: '',
      file: null,
      deleteFile: false
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      const data = JSON.parse(JSON.stringify(this.data));
      data.comentario = this.comment;

      try {
        let response = null;

        if (this.data.diagnostico_comentario_id) {
          if (this.deleteFile) {
            data.delete_file = true;
          }

          response = await updateCommentSpecialist(data);
        } else {
          response = await addCommentSpecialist(data);
        }

        let diagnosisComment = response.data.result.data;

        if (this.file) {
          if (!data.comentario) {
            alert(
              'Você precisa adicionar um comentário para fazer o envio do arquivo'
            );
            return;
          }

          response = await uploadCommentFileSpecialist(
            diagnosisComment.diagnostico_comentario_id,
            this.file
          );
          diagnosisComment = response.data.result.data;
        }

        this.$emit('onSaveComment', diagnosisComment);
      } catch (error) {
        this.$store.state.modal.isOpenModalErrorRequest = true;
      }
    },
    openModal() {
      this.comment = this.data.comentario;
      this.file = null;
      this.deleteFile = false;
    },
    cancel() {
      this.$emit('closeModal');
    },
    handleDeleteFile() {
      this.deleteFile = true;
    },
    handleUndoDeleteFile() {
      this.deleteFile = false;
    }
  },
  computed: {
    isOpenModal: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      }
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.openModal();
      }
    }
  }
};
</script>
