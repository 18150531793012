<template>
  <b-modal v-model="isOpenModalAddComment" @ok="handleOk">
    <form @submit.stop.prevent="handleSubmit">
      <b-form-group label-for="comment-textarea">
        <b-form-textarea
          id="comment-textarea"
          v-model="comment"
          rows="5"
          placeholder="Texto com o comentário..."
        />
      </b-form-group>
    </form>

    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <div style="margin: 0 auto">
        <h5 style="color: var(--grey)">
          {{ defaultComment ? 'Editar' : 'Adicione o seu' }} comentário
        </h5>
      </div>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="md" variant="danger" @click="cancel()">
        Cancelar
      </b-button>

      <b-button size="md" variant="success" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { updateCommentWithFile } from '@/services/requests/diagnosis';

export default {
  name: 'ModalAddComment',
  props: {
    urlResource: {
      type: String,
      required: true
    },
    idColumn: {
      type: String,
      required: true
    },
    commentColumn: {
      type: String,
      required: true
    },
    urlAppend: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      comment: '',
      defaultComment: null
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      const data = JSON.parse(JSON.stringify(this.itemModalAddComment));
      data[this.commentColumn] = this.comment;

      const id = data[this.idColumn];
      const urlAppend = this.urlAppend || '';

      updateCommentWithFile(this.urlResource, id, urlAppend, data)
        .then(() => {
          this.isOpenModalAddComment = false;

          this.$emit('onSuccess', this.comment);
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    ...mapState('modal', ['itemModalAddComment']),
    isOpenModalAddComment: {
      get() {
        return this.$store.state.modal.isOpenModalAddComment;
      },
      set(value) {
        this.$store.state.modal.isOpenModalAddComment = value;
      }
    }
  },
  watch: {
    itemModalAddComment(value) {
      if (value) {
        const comment = value[this.commentColumn];

        this.defaultComment = comment;
        this.comment = comment;
      }
    }
  }
};
</script>
