<template>
  <!-- v-b-modal.modal-prevent-closing -->
  <div>
    <b-button-group class="b-btn-group" @click="openModalEditComment">
      <b-button :style="styleIcon" variant="dark-primary">
        <i :class="icons" style="font-size: var(--spacer)"></i>
      </b-button>
      <b-button :style="style" variant="primary">
        {{ title }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  props: {
    openModalEditComment: Function,
    title: String,
    icon: String,
    style_text: {
      type: String,
      required: false,
      default: ''
    },
    style_icon: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      show: false
    };
  },

  computed: {
    style() {
      return `
        ${this.style_text}
      `;
    },
    styleIcon() {
      return `
        ${this.style_icon}
      `;
    },
    icons() {
      return `bx bx-${this.icon}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.b-btn-group {
  border: none;
}
</style>
